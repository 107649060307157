:root {
    --invalid-input: rgb(202, 1, 1);
  }

  .row-class{
    background-color: #f00;
  }

.filterBar{
  color:#0078D4;
}

.filterBar:hover{
  text-decoration: underline;
}

.c-button{
    background-color: #0078D4;
    cursor: pointer;
    color: #fff;
    border-width: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.infoCard{
  background: rgb(172,0,251);
  background: linear-gradient(90deg, rgba(172,0,251,1) 0%, rgba(172,0,251,1) 32%, rgba(84,0,253,1) 69%, rgba(12,0,255,1) 100%);
}

.c-input{
    height:45px;
    background-color:#eee;
    width:'100%';
    border-radius:15px;
    padding-right:20px;
    padding-left:20px;
    border-width: 0px;
}

.c-input-invalid{
    height:45px;
    background-color:#eee;
    width:'100%';
    border-radius:15px;
    border:none;
    outline: 1px solid var(--invalid-input) ;
    padding-right:20px;
    padding-left:20px
}

.delete_variant_dialog{
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 70px;
  top: 0px;
  z-index: 1000;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px #0003;
}

.delete_multiplevariant_dialog{
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top:30px;
  right:-28px;
  z-index: 50;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px #0003;
}

.options-menu{
  background-color: #fff;
  border: solid 0.3px #eee;
  border-radius: 5px;
  padding: 0px;
  width: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  z-index: 50;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px #0003;
}

.menu-child:hover{
  background-color: #eee;
}

.multiColor{
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background: rgb(255,252,0);
  background: linear-gradient(90deg, rgba(255,252,0,1) 0%, rgba(255,0,0,1) 35%, rgba(0,212,255,1) 100%)
}

.custom-layout-sidebar{
  height: calc(100vh - 5rem);
  top: 5rem;
  left: 0rem;
  border-radius: 0px;
  width: 280px
}

.custom-container-main-layout{
  padding: 6rem 1rem 1rem 0rem;
}

.custom-top-bar{
  padding: 0rem 1rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.ul-item-active{
    color: white;
    font-weight: bold;
    /* background-color: rgb(99,102,240); */
    background-color: rgb(240, 167, 99);
    box-shadow: 4px 5px 10px 2px #0004;
}
